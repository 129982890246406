import { useAppI18N } from '@/app'
import { AppStatus } from '@/redux'
import { analytics, APIClient } from '@/services'
import { Button, View } from '..'
import { authWall, useAct } from '@/utils'

export const OrgReviewButtons = ({ onShare, isMyOrganisation = false }) => {
  const { t } = useAppI18N()
  const { onPressStories, onPressOrganisation } = useAct()

  

  

  if (!isMyOrganisation) return null

  return (
    <View variants={['column', 'gap:2', 'fullWidth', 'paddingHorizontal:2']}>
      <View variants={['gap:1', 'fullWidth']}>
        <Button
          debugName='Add your views'
          onClick={authWall(onPressOrganisation)}
          variants={['outline', 'large', 'fullWidth']}
          icon='edit'
          text={t('organisations.addYourViews')}
        />
        <Button
          debugName='Success Stories'
          onClick={authWall(onPressStories)}
          variants={['outline', 'large', 'fullWidth']}
          icon='plus'
          text={t('organisations.addSuccessStory')}
        />
      </View>
      {
        onShare ? (
          <Button
            debugName='Invite to review'
            onPress={onShare}
            variants={['outline', 'large']}
            text={t('organisations.inviteColleagues')}
          />
        ) : null
      }
    </View>
  )
}
