import { AppStatus } from '@/redux'
import { analytics, AnalyticsEvents, APIClient } from '@/services'
import { authWall } from '../authWall'

export async function handleOnboardingValidations(needsEmail = true) {
  const isLoggedIn = APIClient.Session.isLoggedIn()
  const profile = APIClient.Session.getProfile()

  if (!isLoggedIn) {
    authWall(() => {}, true)()
    return false
  }

  const signupComplete = await APIClient.Session.checkSignupCompletion(profile)
  if (!signupComplete.isComplete) {
    if(signupComplete.nextStep === 'VerifyEmail' && !needsEmail){
      return true
    }
    // @ts-ignore
    AppStatus.setModal(signupComplete.modal ?? signupComplete.nextStep)
    return false
  }

  return true
}

export function useAct() {
  const pulse = APIClient.PulseQuestions.usePulseQuestions()
  const { isLoggedIn, profile } = APIClient.Session.useSession()



  async function onPressOrganisation() {
    if (await handleOnboardingValidations()) {
      AnalyticsEvents.pressContribute()
      if (!pulse.available) {
        AppStatus.setModal('noReviewQuestions')
        return
      }
      pulse.openQuestions('act_page')
    }
  }

  async function onPressStories() {
    if (await handleOnboardingValidations()) {
      AnalyticsEvents.pressContribute()
      analytics.track('story_add_start', { source_from: 'act' })
      AppStatus.setModal('createStory')
    }
  }

 
  return {
    onPressOrganisation,
    onPressStories
 
  }
}
