import React from 'react'
import { Modal } from '@codeleap/web'
import { Image, View, ActionIcon, Text, Button } from '@/components'
import { Celebration2 } from '@/app/assets/images'
import { variantProvider } from '@/app'
import { useIsMobile } from '@/utils'
import { APIClient } from '@/services'
import { AppStatus, Pulse, useAppSelector } from '@/redux'
import { navigate } from 'gatsby'
import { onUpdate } from '@codeleap/common'
import { analytics } from '@/services'

export const ReviewOrgSuccessModal = () => {
  const status = useAppSelector(state => state?.AppStatus?.status)
  const visible = useAppSelector(state => state?.Pulse.successOpen)

  const { moreAvailable, onAnswerMore, refetch } = APIClient.PulseQuestions.usePulseQuestions()
  const { profile } = APIClient.Session.useSession()

  const isMobile = useIsMobile()

  const Texts = React.useCallback(() => (
    <View variants={['column', 'center']}>
      <Text variants={['h1']} text='Thank You!' />
      <Text variants={['p1']} text='Thanks for your valuable input.' />
    </View>
  ), [])

  const ModalHeader = React.useCallback(() => (
    <View variants={['column', 'justifyEnd', 'relative', 'flex', 'paddingHorizontal:4', 'bg:primary1', 'fullWidth']}>
      {isMobile && <Texts />}
      <ActionIcon
        debugName='successPulse:close'
        name={'x'}
        variants={['minimal', 'primary3', 'alignSelfEnd', 'absolute', 'iconSize:3']}
        style={styles.modalHeaderIcon}
        onPress={() => {
          onAnswerMore()
          Pulse.toggleSuccess(false)
        }}
      />
      <Image source={Celebration2} objectFit='contain' style={styles.image} />
    </View>
  ), [isMobile])

  const onAddStory = () => {
    onAnswerMore()
    Pulse.toggleSuccess(false)
    analytics.track('story_add_start', { source_from: 'after_review' })
    AppStatus.setModal('createStory')
  }

  const onViewResults = () => {
    onAnswerMore()
    Pulse.toggleSuccess(false)
    AppStatus.set('loading')

    setTimeout(() => {
      AppStatus.set('idle')
      navigate(`/organisations/${profile?.organization?.id}/`)
    }, 2500)
  }

  const handleAnswerMore = async () => {
    Pulse.toggleSuccess(false)
    await onAnswerMore(true)
    Pulse.toggleModal()
  }

  onUpdate(() => {
    if (visible) {
      refetch()
    }
  }, [visible])

  return (
    <Modal
      showClose={false}
      renderHeader={ModalHeader}
      variants={[isMobile && 'fullscreen']}
      styles={{ box: styles.modalBox, body: styles.modalBody }}
      visible={visible && !(status === 'loading' || status === 'done')}
      toggle={() => null}
    >
      {!isMobile && <Texts />}

      <View variants={['column', 'gap:2', 'marginTop:4']} responsiveVariants={{ small: ['marginTop:0'] }}>
        <Button
          debugName='successPulse:story'
          text='Add a climate action success story'
          onPress={onAddStory}
          variants={['pill', 'large']}
        />

        <View variants={['row', 'fullWidth', 'gap:2']}>
          <Button
            debugName='successPulse:results'
            text='View my organisation'
            onPress={onViewResults}
            variants={['pill', 'large', 'flex', 'outline']}
          />
        </View>
      </View>
    </Modal>
  )
}

const MODAL_WIDTH = 400

const styles = variantProvider.createComponentStyle((theme) => ({
  modalBody: {
    ...theme.presets.column,
    ...theme.spacing.padding(4),
    paddingTop: 0,
    backgroundColor: theme.colors.primary1,
    flex: '1',

    [theme.media.down('small')]: {
      ...theme.spacing.padding(2),
      maxHeight: 'none',
      width: '100vw',
    },
  },
  modalBox: {
    // @ts-ignore
    borderRadius: theme.borderRadius.large,
    width: MODAL_WIDTH,
    ...theme.spacing.padding(0),

    [theme.media.down('small')]: {
      ...theme.presets.fullWidth,
      height: 'auto',
      borderRadius: 0,
      width: '100vw',
    },
  },
  modalHeaderIcon: {
    top: theme.spacing.value(4),
  },
  image: {
    height: 400,
  },
}), true)
