import { React, variantProvider } from '@/app'
import { ActionIcon, Text, View, CenterWrapper } from '@/components'
import { Chapter } from '@/types'
import { AnyFunction } from '@codeleap/common'

type LessonViewHeaderProps = {
  pages: number
  currentPage: number
  text: string
  chapterId: Chapter['id']
  onLeaveLesson: AnyFunction
}

export const LessonViewHeader = (props: LessonViewHeaderProps) => {
  const {
    pages = 2,
    currentPage = 0,
    text,
    onLeaveLesson,
  } = props

  const steps = Array(pages).fill(0)

  return (
    <CenterWrapper styles={{ 'innerWrapper': styles.header }}>
      <View variants={['row', 'center', 'gap:1', 'pointerEvents:none']}>
        {steps?.map((_, i) => {
          if (i == 0) return null
          const isOldStep = i <= currentPage

          return (
            <View
              key={'learn-view-header-' + i}
              css={[styles.step, isOldStep && styles.stepFill]}
            />
          )
        })}
      </View>

      <View style={styles.info}>
        <View style={styles.text} variants={['paddingHorizontal:1', 'border-radius:tiny', 'bg:neutral2', 'center', 'pointerEvents:none']}>
          <Text
            variants={['p3', 'color:neutral9', 'ellipsis']}
            text={text}
          />
        </View>

        <ActionIcon
          debugName='cancel lesson view'
          name='x'
          variants={['icon:lessonView']}
          onPress={onLeaveLesson}
        />
      </View>
    </CenterWrapper>
  )
}

const stepHeight = 4

const styles = variantProvider.createComponentStyle(theme => ({
  header: {
    width: '100vw',
    maxWidth: '100vw',
    height: 'auto',
    ...theme.presets.absolute,
    right: 0,
    left: 0,
    top: theme.spacing.value(6),
    ...theme.presets.column,
    zIndex: 10,
    paddingLeft: theme.spacing.value(2),
    paddingRight: theme.spacing.value(2),

    [theme.media.down('small')]: {
      top: theme.spacing.value(3),
    }
  },
  step: {
    flex: 1,
    height: stepHeight,
    borderRadius: theme.borderRadius.rounded,
    backgroundColor: theme.colors.neutral4,
  },
  stepFill: {
    backgroundColor: theme.colors.primary3,
  },
  info: {
    ...theme.presets.row,
    ...theme.presets.alignStart,
    ...theme.presets.justifySpaceBetween,
    ...theme.spacing.marginTop(4),
    ...theme.spacing.gap(2),

    [theme.media.down('small')]: {
      ...theme.spacing.marginTop(2),
    }
  },
  text: {
    maxWidth: theme.presets.calcWidth(theme.spacing.value(2 * 2) + theme.values.iconSize[4] + theme.spacing.value(2)),
    height: theme.values.iconSize[4],
    overflow: 'hidden',
  }
}), true)
