import React, { createContext, useContext } from 'react'
import { AppImages, useAppI18N, variantProvider } from '@/app'
import { View, Text, Button, ActivityIndicator, ActionIcon, Image, DiscoverScoreModal, NoReviewsPlaceholder } from '@/components'
import { arePropsEqual, TypeGuards, useMemo } from '@codeleap/common'
import { ScoreModal, ScoreNotFound } from './components/'
import { Organisation } from '@/types'
import { APIClient, analytics } from '@/services'
import { ProgressCircle } from '../Progress/Circle'
import { authWall, formatNumberToShort, formatScore, useIsMobile, useMediaQueryDown, useModal } from '@/utils'
import { Score } from './Score'
import { AppStatus } from '@/redux'
import { EmptyPlaceholder } from '@codeleap/web'

type ClimateEngagementScoreProps = {
  variant?: 'modal' | 'withoutModal'
  organisation?: Organisation
  score?: Organisation['score']
  modalOpen?: boolean
  toggleModal?: () => void
  showLastViewedText?: boolean
  showReviews?: boolean
  blurred?: boolean
  loading?: boolean
}

const BREAKPOINT = 'small'

type TCESContext = ClimateEngagementScoreProps & {
  hasModal: boolean
}
const CESContext = createContext({} as TCESContext)

const useCESContext = () => {
  return useContext(CESContext)
}

const BlurredScores = () => {
  const { organisation } = useCESContext()

  return <View style={styles.blurWrapper}>
    <Image source={AppImages.ScoreBlurred} style={styles.blurImage}/>
    <DiscoverScoreModal.Button style={styles.learnMore} />
    <DiscoverScoreModal organisation={organisation}/>
  </View>
}

type ScoresProps = {
  score?: Organisation['score']
}

const formatOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }

const Scores = (props: ScoresProps) => {
  const { score } = props

  const { t } = useAppI18N()

  const { hasModal, organisation } = useCESContext()
  
  const isReviewsEmpty = !score?.number_of_reviews
  
  const wrapperStyles = hasModal ? styles.wrapper : styles.modalWrapper

  if (isReviewsEmpty) {
    return <View variants={['alignCenter', 'justifyCenter']}>
      <View style={wrapperStyles}>
        <ScoreNotFound />
      </View>
    </View>
  }

  const scoreRanks = APIClient.Answers.useScoreRanks()


  const categoryKeys = Object.keys(score?.categories)

  const { categories, subcategories } = APIClient.Organisations.useCategories()


  const organisationRank = scoreRanks.data?.find((rank) => score.overall >= rank.score)

  const isMid = useMediaQueryDown('mid')

  return <View variants={['column', 'gap:2', 'fullWidth']}>
    <View variants={['alignCenter', 'justifyCenter', 'gap:4', 'fullWidth', 'relative']}>
      <View variants={['column', 'alignCenter', 'justifyCenter', (hasModal || isMid) && 'flex']}>
        <ProgressCircle
          progress={score.overall}
          image={organisationRank?.web_image || organisationRank?.mobile_image}
          variants={['noLabel', 'largeImage', 'large']}
          text={organisationRank?.label}
        />
        <View variants={['column', 'alignCenter', 'justifyCenter']}>
          <Text variants={['hx', 'color:neutral9']}>{formatScore(score.overall)}%</Text>
          <Text variants={['p2', 'color:neutral5', 'marginTop:-1']} text={t('organisations.score.average')} />
        </View>
      </View>

      <View style={styles.verticalSeparator} />

      <View variants={
        ['alignCenter',
          'justifyCenter',
          (hasModal || isMid) && 'flex',
        ]}>
        <View variants={['column', 'gap:2']}>
          {categoryKeys?.map((categoryId) => {
            const category = categories[categoryId]

            const categoryScore = score.categories[categoryId].total

            return <Score
              key={category?.name }
              name={category.name}
              score={categoryScore}
              img={category.web_icon || category.mobile_icon}
            />
          })}
        </View>
      </View>
    </View>

  </View>
}

const Reviews = (props: ScoresProps & { showLastViewedText?: boolean }) => {
  const { score, showLastViewedText = false } = props

  const { t } = useAppI18N()

  if(!score) return null
  
  const lastViewed = score?.last_review_date
    ? new Date(score?.last_review_date)?.toLocaleDateString(undefined, formatOptions)
    : null
  const showOverviewText = !!score.last_review_date && !!score.number_of_reviews && !!score.number_of_reviewers
  return <>
    {showOverviewText && !showLastViewedText && <Text variants={['p4', 'color:neutral5']}>
      {t(`organisations.score.${score.number_of_reviews !== 1 ? 'answers' : 'answer'}`, { nReviews: formatNumberToShort(score.number_of_reviews) })}{'\u00A0'}
      {t('from')}{'\u00A0'}
      {t(`organisations.score.${score.number_of_reviewers !== 1 ? 'reviewers' : 'reviewer'}`, { nReviewers: formatNumberToShort(score.number_of_reviewers) })}
    </Text>}
    {showLastViewedText && <Text variants={['p4', 'color:neutral5']}>
      {score?.number_of_reviews} {t(`organisations.score.${score?.number_of_reviews !== 1 ? 'lastReviews' : 'lastReview'}`, { nLastReviewed: lastViewed })}
    </Text>}
  </>

}

export const ClimateEngagementScore= (props: ClimateEngagementScoreProps) => {
  const {
    modalOpen,
    toggleModal,
    variant = 'modal',
    organisation,
    showLastViewedText = false,
    score = organisation?.score,
    showReviews = true,
    blurred,
    loading = false
  } = props

  const _modal = useModal()

  const [visible, toggle] = TypeGuards.isFunction(toggleModal) && TypeGuards.isBoolean(modalOpen) ? [modalOpen, toggleModal] : [_modal.visible, _modal.toggle]
  const { t } = useAppI18N()

  const { categories, subcategories } = APIClient.Organisations.useCategories()

  const isMobile = useIsMobile()

  const overviewData = useMemo(() => {

    if (score && categories) {
      const formattedCategories = Object.keys(categories).reduce((acc, category) => {
        return {
          ...acc,
          [categories[category].name]: score.categories[category].total?.toFixed() || 0,
        }
      }, {})

      return { average_score: score.overall.toFixed(), ...formattedCategories }
    }
  }, [score, categories])

  const hasModal = variant === 'modal'

  const wrapperStyles = hasModal ? styles.wrapper : styles.modalWrapper


  if (!categories || !subcategories || loading) {
    return <View variants={['center', 'flex']}>
      <ActivityIndicator debugName={'ClimateEngagementScore Indicator'} />
    </View>
  }

 
  const onToggle = () => {
    if (!_modal.visible) {
      analytics.track('climate_empowerment_score', overviewData)
    }
    authWall(_modal.toggle)()
  }

  return (
    <CESContext.Provider value={props}>

      <View variants={['alignCenter', 'justifyCenter', 'fullWidth']}>
        <View style={wrapperStyles} variants={['column', 'gap:2']}>
          {blurred ? <BlurredScores /> : <>
            <Scores score={score} />
            <View
              variants={['column', 'justifyCenter', 'alignCenter', 'gap:2']}
              responsiveVariants={{ [BREAKPOINT]: ['column', 'justifyCenter', 'alignCenter', 'gap:2'] }}
            >
              <Reviews score={score} showLastViewedText={showLastViewedText} />

            </View>

          </>}

        </View>
      </View>
    </CESContext.Provider>
  )
}

type ScoreHeaderProps = {
  onExpand?: () => void
}
export function ScoreHeader(props: ScoreHeaderProps) {
  const { onExpand } = props

  const { t } = useAppI18N()

  return <View variants={['alignCenter', 'justifySpaceBetween', 'alignSelfStart', 'fullWidth']}>
    <Text variants={['h3', 'color:neutral9']} responsiveVariants={{ [BREAKPOINT]: ['h2', 'color:neutral9'] }}>{t('organisations.scoreTitle')}</Text>
    {
      onExpand && <ActionIcon
        name='arrow-right'
        variants={['minimal']}
        onPress={onExpand}
        debugName={'myOrganisation arrow-right icon'}
      />
    }

  </View>
}



const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    maxWidth: '460px',
  },
  modalWrapper: {
    width: '100%',
  },
  verticalSeparator: {
    width: '1px',
    // position: 'absolute',
    // height: '90%',
    // top: '10%',
    // bottom: '10%',
    ...theme.spacing.marginVertical(2),
    alignSelf: 'stretch',
    justifySelf: 'center',
    backgroundColor: theme.colors.neutral3,

  },
  placeholderCircle: {
    width: '130px',
    height: '130px',
    backgroundColor: theme.colors.primary3,
    borderRadius: theme.borderRadius.rounded,
  },
  tinyPlaceholderCircle: {
    width: '64px',
    height: '64px',
    backgroundColor: theme.colors.primary3,
    borderRadius: theme.borderRadius.rounded,
  },
  learnMore: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 1,

  },
  blurWrapper: {
    maxWidth: '460px',
    position: 'relative',
  },
  blurImage: {
    width: '100%',
    aspectRatio: 776 / 620,
    zIndex: 0,
  },
}), true)
