import { AppImages, I18N, variantProvider } from '@/app'
import { Button, Icon, Image, Modal, Text, View, Alert } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'
import { APIClient, getSharedSettings } from '@/services'
import { analytics } from '@/services'
import { useAnimatedStyle, useAppStatus, useIsMobile } from '@/utils'
import { AnyFunction, TypeGuards, useI18N } from '@codeleap/common'
import { motion } from 'framer-motion'
import { navigate } from 'gatsby'
import React from 'react'

type ModalLearnDoneProps = {
  toggle: AnyFunction
  visible: boolean
  name: string
  onLeave?: AnyFunction
  illustration: 'lesson' | 'chapter'
  onShare?: AnyFunction
  onContinue?: AnyFunction
}

const TRANSITION_STATUS_DONE = 2500

const GetOrgInvolvedModal = ({ onDone = null }) => {
  const visible = useAppSelector(state => state.AppStatus.modals.getOrgInvolved)

  const toggle = () => {
    AppStatus.setModal('getOrgInvolved')
  }

  const onConfirm = async () => {
    AppStatus.set('loading')

    try {
      analytics.track('get_org_involved')
      await APIClient.Organisations.getOrganisationInvolved()
      await APIClient.Session.QueryKeys.me.refresh()
    } catch (e) {
      logger.error(e)
    }

    setTimeout(() => {
      AppStatus.set('done')
      if (TypeGuards.isFunction(onDone)) onDone()
    }, TRANSITION_STATUS_DONE)
  }

  return <Alert
    debugName='GetOrgInvolvedModal'
    visible={visible}
    toggle={toggle}
    title={I18N.t('modals.getOrganisationInvolved.title')}
    description={I18N.t('modals.getOrganisationInvolved.description')}
    onAction={onConfirm}
    type='warn'
    isRow
    actionButtonProps={{
      text: I18N.t('modals.getOrganisationInvolved.confirm'),
    }}
    dismissButtonProps={{
      text: I18N.t('modals.getOrganisationInvolved.cancel'),
    }}
    styles={{
      'modalWrapper': {
        zIndex: 9999,
      },
    }}
  />
}

export const ModalLearnDone = (props: ModalLearnDoneProps) => {
  const {
    toggle,
    visible,
    name,
    onLeave = null,
    illustration,
    onShare = null,
    onContinue: _onContinue = null,
  } = props

  const status = useAppStatus()
  const isMobile = useIsMobile()
  const { hasUnknownOrg, profile, isLoggedIn } = APIClient.Session.useSession()

  const { t } = useI18N()

  const onContinue = () => {
    _onContinue?.()

    APIClient.Learn.chaptersManager.refresh()
    APIClient.Learn.modulesManager.refresh()

    toggle(false)
    onLeave?.()

    if (!isLoggedIn) {
      navigate('/auth/signup/')
      return
    } 
  }

  const shareProgress = () => {
    analytics.track('lesson_progress_share')
    onShare?.()
  }

  const getOrganisationInvolved = () => {
    if (hasUnknownOrg) {
      AppStatus.setModal('unknownOrganisation')
      return
    }

    if (!profile.can_get_organization_involved) {
      alert(t('modals.getOrganisationInvolved.notAvailable.description'))
      return
    }

    AppStatus.setModal('getOrgInvolved')
  }

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: visible ? 1 : 0,
      scale: visible ? 1 : 0,
      transition: {
        duration: 0.5,
      },
    }
  }, [visible])

  const animatedTextStyle = useAnimatedStyle(() => {
    return {
      opacity: visible ? 1 : 0,
      transition: {
        duration: 0.7,
      },
    }
  }, [visible])

  const Progress = React.useCallback((props: any = {}) => (
    <View {...props} variants={['fullWidth', 'center']} style={styles.progress}>
      <View variants={['bg:neutral1', 'row', 'border-radius:small', 'padding:2', 'center', 'justifySpaceBetween', 'elevated']}>
        <View variants={['bg:primary3', 'border-radius:tiny', 'padding:2']}>
          <Icon debugName='modal done star' name='star-fill' variants={['iconSize:1', 'neutral10']} />
        </View>

        <Text variants={['h1', 'marginLeft:2']} text='100%' />
      </View>
    </View>
  ), [])

  return <>
    <Modal
      visible={visible && !status.isVisible}
      toggle={toggle}
      debugName={`Modal ${name} done`}
      title={null}
      variants={[isMobile && 'fullscreen', 'center']}
      scroll={false}
      showClose={false}
      closable={false}
      styles={{
        'body': styles.body,
        'box': styles.box,
        'innerWrapper': styles.wrapper,
      }}
    >
      <View style={styles.scroll}>
        <View style={styles.content}>
          <Progress up={'small'} />

          <motion.div {...animatedTextStyle}>
            <Text variants={['h1', 'color:neutral10', 'marginBottom:1', 'textCenter']} text={t('learn.components.doneModal.title')} />
            <Text variants={['p1', 'color:neutral10', 'textCenter', 'paddingHorizontal:1']} text={`${t('learn.components.doneModal.subtitle')}  ‘${name}‘!`} />
          </motion.div>

          <motion.div {...animatedStyle} style={styles.imageWrapper}>
            <Image
              source={illustration === 'lesson' ? AppImages.celebration2 : AppImages.trophy}
              objectFit='cover'
              style={styles.image}
            />

            <Progress down={'small'} />
          </motion.div>
        </View>

        <View style={styles.footer}>
          {isLoggedIn ? (
            <>
              <Button
                variants={['fullWidth', 'border-radius:rounded']}
                text={t('learn.components.doneModal.shareButton')}
                debugName={`Modal done share button`}
                onPress={shareProgress}
              />
              <Button
                variants={['fullWidth', 'outline', 'border-radius:rounded']}
                text={t('learn.components.doneModal.organisationButton')}
                debugName={`Modal organisation`}
                onPress={getOrganisationInvolved}
              />
            </>
          ) : null}
          <Button
            variants={['fullWidth', 'border-radius:rounded']}
            text={t('learn.components.doneModal.continueButton')}
            debugName={`Modal done continue`}
            onPress={onContinue}
          />

        </View>
      </View>
    </Modal>

    <GetOrgInvolvedModal
      onDone={onContinue}
    />
  </>
}

const IMAGE_WIDTH = 310
const MODAL_WIDTH = 500

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    overflowY: 'auto',
    zIndex: 10000,
  },
  box: {
    backgroundColor: theme.colors.primary1,
    padding: theme.spacing.value(2),

    [theme.media.down('small')]: {
      ...theme.presets.center,
    },
  },
  body: {
    backgroundColor: theme.colors.primary1,
    padding: theme.spacing.value(0),

    [theme.media.up('small')]: {
      width: '100vw',
      maxWidth: MODAL_WIDTH,
    },

    [theme.media.down('small')]: {
      paddingLeft: theme.spacing.value(1),
      paddingRight: theme.spacing.value(1),
    },
  },
  scroll: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: theme.spacing.value(2),
    paddingTop: theme.spacing.value(0),

    [theme.media.down('small')]: {
      paddingTop: theme.spacing.value(2),
    },
  },
  content: {
    flexDirection: 'column-reverse',

    [theme.media.down('small')]: {
      flexDirection: 'column',
    },
  },
  imageWrapper: {
    width: '100%',
    display: 'flex',
    ...theme.presets.center,
    ...theme.presets.relative,
    ...theme.spacing.marginTop(-4),

    [theme.media.down('small')]: {
      ...theme.spacing.marginTop(0),
    },
  },
  image: {
    width: IMAGE_WIDTH,

    [theme.media.down('small')]: {
      ...theme.spacing.marginTop(2),
    },
  },
  progress: {
    ...theme.spacing.marginTop(4),

    [theme.media.down('small')]: {
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      position: 'absolute',
      width: '100vw',
      ...theme.spacing.marginTop(0),
    },
  },
  footer: {
    maxWidth: MODAL_WIDTH * 0.7,
    alignSelf: 'center',
    justifySelf: 'center',
    ...theme.presets.column,
    ...theme.presets.fullWidth,
    ...theme.spacing.marginTop(2),
    ...theme.spacing.gap(2),
  },
}), true)
